import { ElMessage } from 'element-plus'
export const getDate = (val, status) => {
  let date = new Date(val * 1000)
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let week = date.getDay()
  let d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  let h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  let m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  if (status == 'chat') {
    return h + ':' + m
  } else if (status == 'allTime') {
    return {
      year,
      month,
      week,
      d,
      h,
      m,
      s
    }
  } else {
    return year + '-' + month + '-' + d + ' ' + h + ':' + m + ':' + s
  }
}

// 判断每条聊天记录的时间戳与现在的时间状态
export const detectionTime = (timeStr, obj) => {
  let todayInfo = getDate(new Date().getTime() / 1000, 'allTime')
  let todayStart = new Date(todayInfo.year + '-' + todayInfo.month + '-' + todayInfo.d).getTime()
  timeStr = timeStr * 1000
  // 昨天0点的时间戳
  let yestodayStart = todayStart - 60 * 60 * 24 * 1000
  if (timeStr > todayStart) {
    // 是今日时间
    return `${obj.h}:${obj.m}`
  } else {
    // 不是今天
    if (timeStr >= yestodayStart && timeStr < todayStart) {
      return `昨天  ${obj.h}:${obj.m}`
    } else if (timeStr < yestodayStart) {
      // 今天星期几
      let alreadyDay = todayInfo.week === 0 ? 7 : todayInfo.week
      let result = ''
      // 今天在星期二后面
      if (timeStr <= todayStart - 60 * 60 * 24 * 1000 * (alreadyDay - 1)) {
        return `${obj.year}年-${obj.month}月-${obj.d}日  ${obj.h}:${obj.m}`
      }
      if (alreadyDay > 2) {
        for (let i = 2; i < alreadyDay; i++) {
          // 从前天开始判断
          let thatDay = todayStart - 60 * 60 * 24 * 1000 * i
          if (timeStr >= thatDay && timeStr <= thatDay + 60 * 60 * 24 * 1000) {
            let thatWeek = alreadyDay - i
            switch (thatWeek) {
              case 1:
                result = `星期一  ${obj.h}:${obj.m}`
                break
              case 2:
                result = `星期二  ${obj.h}:${obj.m}`
                break
              case 3:
                result = `星期三  ${obj.h}:${obj.m}`
                break
              case 4:
                result = `星期四  ${obj.h}:${obj.m}`
                break
              case 5:
                result = `星期五  ${obj.h}:${obj.m}`
                break
              case 6:
                result = `星期六  ${obj.h}:${obj.m}`
                break
            }
            break
          }
        }
        return result
      }
    }
  }
}
// 手机号验证
export const inspectPhone = (val) => {
  let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/
  return myreg.test(val)
}

// 验证邮箱
export const checkEmail = (rule, value, callback) => {
  let reg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
  if (value == '' || value === null) {
    callback(new Error('请输入邮箱'))
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确格式的邮箱'))
  } else {
    callback()
  }
}

// 获取聊天记录的最后一天
export const getMaxKey = (obj) => {
  let keys = Object.keys(obj)
  return Math.max(...keys)
}

// 存储聊天记录
export const addChatList = (chatAllRecord, data) => {
  // false 默认是客户发送给客服
  let flag = false
  if (
    data.msgtype == 'user_open_offline' ||
    data.msgtype == 'uniacid_online' ||
    data.msgtype == 'uniacid_auto_reply'
  ) {
    flag = true
  }
  if (chatAllRecord.value[`${!flag ? data.toUserFd.uid : data.fromUserFd.uid}`] === undefined) {
    // 聊天记录中不含有该客服账号的记录
    chatAllRecord.value[`${!flag ? data.toUserFd.uid : data.fromUserFd.uid}`] = {}
    chatAllRecord.value[`${!flag ? data.toUserFd.uid : data.fromUserFd.uid}`][
      `${!flag ? data.fromUserFd.uid : data.toUserFd.uid}`
    ] = {}
    chatAllRecord.value[`${!flag ? data.toUserFd.uid : data.fromUserFd.uid}`][
      `${!flag ? data.fromUserFd.uid : data.toUserFd.uid}`
    ]['1'] = [data]
  } else {
    // 聊天记录中含有该客服账号的记录
    if (
      chatAllRecord.value[`${!flag ? data.toUserFd.uid : data.fromUserFd.uid}`][
        `${!flag ? data.fromUserFd.uid : data.toUserFd.uid}`
      ] === undefined
    ) {
      // 聊天记录中不含有该客服账号与该用户账号的记录
      let obj = {}
      obj['1'] = [data]
      chatAllRecord.value[`${!flag ? data.toUserFd.uid : data.fromUserFd.uid}`][
        `${!flag ? data.fromUserFd.uid : data.toUserFd.uid}`
      ] = obj
    } else {
      // 聊天记录中含有该客服账号与该用户账号的记录
      let obj =
        chatAllRecord.value[`${!flag ? data.toUserFd.uid : data.fromUserFd.uid}`][
          `${!flag ? data.fromUserFd.uid : data.toUserFd.uid}`
        ]
      let startTime = new Date(new Date().toLocaleDateString()).getTime() / 1000
      let endTime =
        (new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000) / 1000
      let maxKey = getMaxKey(obj)
      let lastTime = obj[`${maxKey}`][0].sendTime
      if (lastTime >= startTime && lastTime <= endTime) {
        // 有今天的聊天记录
        if (data.sendTime - obj[`${maxKey}`][obj[maxKey].length - 1].sendTime >= 2 * 60) {
          // 这条消息距离上次发送消息超过五分钟
          obj[`${maxKey}`].push({ sendTime: data.sendTime, type: 'dateLine' })
        }
        obj[`${maxKey}`].push(data)
      } else {
        // 暂无今天的聊天记录
        if (maxKey == 7) {
          // 已经储存了七天的聊天记录
          delete obj['1']
          let replaceList = {}
          replaceList['1'] = obj['2']
          replaceList['2'] = obj['3']
          replaceList['3'] = obj['4']
          replaceList['4'] = obj['5']
          replaceList['5'] = obj['6']
          replaceList['6'] = obj['7']
          replaceList['7'] = [data]
          chatAllRecord.value[`${!flag ? data.toUserFd.uid : data.fromUserFd.uid}`][
            `${!flag ? data.fromUserFd.uid : data.toUserFd.uid}`
          ] = replaceList
          return
        }
        obj[`${maxKey + 1}`] = [data]
      }
    }
  }
  localStorage.setItem('chatList', JSON.stringify(chatAllRecord.value))
}

// 在输入框的指定位置插入内容
export const inputInsert = (item, dom, content, storeRange) => {
  let sotreContent = content
  let startPos = dom.value.$el.firstElementChild.selectionStart
  let endPos = dom.value.$el.firstElementChild.selectionEnd
  // 要插入的字段
  let str = null
  if (item) {
    str = item
    content = content.substr(0, storeRange.start) + str + content.substr(storeRange.end)
  } else {
    str = '\n'
    content = content.substr(0, startPos) + str + content.substr(endPos)
  }
  if (content.length > 500) {
    ElMessage.warning('字符长度不可超过500,请删除一些内容再进行添加')
    return {
      content: sotreContent,
      callback: () => {}
    }
  }
  const callback = () => {
    if (item) {
      dom.value.$el.firstElementChild.focus()
      if (storeRange.start == storeRange.end) {
        storeRange.start += str.length
        storeRange.end += str.length
      } else {
        storeRange.start += str.length
        storeRange.end = storeRange.start
      }
      dom.value.$el.firstElementChild.selectionStart = storeRange.start
      dom.value.$el.firstElementChild.selectionEnd = storeRange.end
    } else {
      dom.value.$el.firstElementChild.selectionStart = startPos + 1
      dom.value.$el.firstElementChild.selectionEnd = startPos + 1
    }
  }
  return {
    content,
    callback
  }
}

//  将base64图片转换成二进制上传
export const dataURItoBlob = (dataURI) => {
  let byteString = atob(dataURI.split(',')[1])
  let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  let ab = new ArrayBuffer(byteString.length)
  let ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}

// 将file文件转成base64 status-0=>获取上传的图片   1=>获取裁剪之后的图片
export const changeBase64 = (item, cloneConfig, status) => {
  let reader = new FileReader()
  let img = new Image()
  img.src = window.URL.createObjectURL(item)
  reader.onload = (e) => {
    let data
    if (typeof e.target.result === 'object') {
      data = window.URL.createObjectURL(new Blob([e.target.result]))
    } else {
      data = e.target.result
    }
    if (status === 0) {
      cloneConfig.value.client_avater_64 = data
      console.log(data)
    } else {
      cloneConfig.value.client_qrcode_64 = data
    }
  }
  reader.readAsDataURL(item)
}

// 将自己发送的消息处理成能储存到本地的格式
export const dealStorageChat = (userInfo, activeMan, content, type) => {
  let nowDate = new Date().getTime() / 1000
  let chatObj = {}
  return (chatObj = {
    action: 104,
    fromUserFd: {
      fd: 7,
      uid: Number(userInfo.id),
      avatar: userInfo.avatar,
      username: userInfo.name
    },
    toUserFd: {
      fd: 10,
      uid: Number(activeMan.uid),
      avatar: activeMan.avatar,
      username: activeMan.name
    },
    content: content,
    type: type,
    msgtype: 'uniacid_online',
    sendTime: nowDate
  })
}
